/**
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */

import PropTypes from 'prop-types';
import './../stylesheets/badge.less';

/**
 * Badge is available in three different sizes and colors.
 * Check prop table for how to animate a badge.
 */
const Badge = (props) => {
  const classNames = ['badge'];

  if (props.branding) {
    classNames.push(`badge-${props.branding}`);
  } else {
    classNames.push('badge-default');
  }

  if (props.size) {
    classNames.push(`badge-${props.size}`);
  }

  if (props.hide) {
    classNames.push(`badge-hide`);
  }

  if (props.bounce) {
    classNames.push(`badge-bounce`);
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <span className={classNames.join(' ')}>{props.children}</span>
  );
};

Badge.propTypes = {
  /**
   * Set bounce to true to run keyframe animation of bounce.
   */
  bounce: PropTypes.bool,
  /**
   * Branding, buildt in support for default, red and orange.
   */
  branding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(['default', 'red', 'orange'])
  ]),
  /**
   * Content
   */
  children: PropTypes.node,
  /**
   * Custom css classes.
   */
  className: PropTypes.string,
  /**
   * Prepare to animate, adds visibility: hidden.
   */
  hide: PropTypes.bool,
  /**
   * Size of the badge, defaults to standard.
   */
  size: PropTypes.oneOf(['standard', 'small', 'large'])
};

Badge.defaultProps = {
  branding: 'default',
  size: 'standard'
};

export default Badge;
